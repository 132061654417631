var $ = jQuery;

const Admin = {
    init() {
        if (window.acf) {
            window.acf.addAction('render_block_preview/type=coverage-table', Admin.enableGroupCollapseInBlock());
        }

        // Disabled until further notice
        //Admin.limitChars();

        Admin.addCollapseClassToAcfFlexibleContentLayout();
        Admin.addPostTypeClassToEditorWrapper();
        Admin.importNetworkHeadersClick();
        Admin.importNetworkListClick();
        Admin.addHubSpotFormLink();
    },
    loaded: false,
    enableGroupCollapseInBlock() {
        var $ = jQuery;

        window.acf.add_action('ready append', function (element) {
            if (!Admin.loaded) {
                var acf_group = jQuery('[class*="acf-field-data-table"].acf-field-group:not([class*="acf-field-data-table-action-button"])');
                if (acf_group.length > 0) {
                    acf_group.addClass('group-collapsed');
                    acf_group.children('.acf-input').addClass('acf-hidden');
                    acf_group.children('.acf-label').on('click', function () {
                        $(this).next('.acf-input').toggleClass('acf-hidden');
                        $(this).parent().toggleClass('group-collapsed');
                    });
                    Admin.loaded = true;
                }
            }
        });
    },
    limitChars() {
        var acf = window.acf;
        if (acf) {
            var selectors = [
                {
                    selector: 'field_accordion_with_slides_title',
                    maxLength: 45,
                },
                {
                    selector: 'field_accordion_with_slides_items_title',
                    maxLength: 35,
                },
                {
                    selector: 'field_accordion_with_slides_items_content',
                    maxLength: 130,
                },
                {
                    selector: 'field_logo_title',
                    maxLength: 45,
                },
                {
                    selector: 'acf-field_testimonial_quote',
                    maxLength: 120,
                },
                {
                    selector: 'field_cards_title',
                    maxLength: 40,
                },
                {
                    selector: 'field_cards_subtitle',
                    maxLength: 165,
                },
            ];
            acf.add_action('ready append', function () {
                Admin.runSelctors(selectors);
            });
        }
    },

    runSelctors(selectors) {
        (function ($) {
            selectors.forEach(function (selector) {
                $('input[id^="acf-block_"][id*="-' + selector.selector + '"]').each(function () {
                    Admin.limitCharacters($(this), selector.maxLength);
                    console.log('limiting characters');
                });
                $('input[id^="' + selector.selector + '"]').each(function () {
                    Admin.limitCharacters($(this), selector.maxLength);
                    console.log('limiting characters');
                });
            });
        })(jQuery);
    },

    limitCharacters(field, maxLength) {
        (function ($) {
            // Create a validation message element
            var validationMessage = $('<div class="acf-validation-message" style="color: red; display: none; margin-top: 4px;">Maximum length of ' + maxLength + ' characters reached</div>');

            // Append the validation message after the field
            field.after(validationMessage);

            field.on('input', function () {
                var value = $(this).val();
                if (value.length > maxLength) {
                    $(this).val(value.substring(0, maxLength));
                    validationMessage.show();
                } else {
                    validationMessage.hide();
                }
            });
        })(jQuery);
    },

    addCollapseClassToAcfFlexibleContentLayout() {
        $('.acf-fc-layout-handle').on('click', function (e) {
            if (e.shiftKey) {
                e.preventDefault();
                var closestLayout = $(this).closest('.layout');
                if (closestLayout.hasClass('-collapsed')) {
                    setTimeout(function () {
                        $('.acf-flexible-content .values .layout').removeClass('-collapsed');
                        $('.acf-flexible-content .acfe-fc-placeholder').addClass('acf-hidden');
                    }, 1);
                } else {
                    setTimeout(function () {
                        $('.acf-flexible-content .values .layout').addClass('-collapsed');
                        $('.acf-flexible-content .acfe-fc-placeholder').removeClass('acf-hidden');
                    }, 1);
                }
            }
        });
    },

    addPostTypeClassToEditorWrapper() {
        setTimeout(() => {
            var bodyClass = $('body[class*="post-type-"]');
            if (bodyClass.length > 0) {
                var style = bodyClass
                    .attr('class')
                    .split(/\s/)
                    .filter(function (cn) {
                        return cn.indexOf('post-type-') === 0;
                    });
                $('.editor-styles-wrapper').addClass(style);
            }
        }, 100);
    },

    importNetworkHeadersClick() {
        $('#import-network-headers').click(function () {
            var button = $(this);
            var nonce = button.data('nonce');
            button.text('Working...');
            $.ajax({
                type: 'POST',
                url: window.ajaxurl,
                data: {
                    action: 'import_network_headers',
                    nonce: nonce,
                },
                success: function (data) {
                    console.log(data);
                    if (!data.success) {
                        button.removeClass('button-primary').addClass('button-danger');
                        button.text('Error: ' + data.data);
                        return;
                    }
                    button.removeClass('button-primary').addClass('button-success');
                    window.location.reload(true);
                    return;
                },
                error: function (xhr, status, error) {
                    console.log(error);
                    button.text('Error');
                },
            });
        });
    },

    importNetworkListClick() {
        $('#import-network-list').click(function () {
            var button = $(this);
            var nonce = button.data('nonce');
            var text = button.text();
            button.text('Working...');
            $.ajax({
                type: 'POST',
                url: window.ajaxurl,
                data: {
                    action: 'import_network_list',
                    nonce: nonce,
                },
                success: function (data) {
                    console.log(data);
                    if (!data.success) {
                        console.log('error');
                        button.removeClass('button-primary').addClass('button-danger');
                        button.text('Error: ' + data.data);
                        return;
                    }
                    button.removeClass('button-primary').addClass('button-success');
                    button.text('Task has been created!');
                    return;
                },
                error: function (xhr, status, error) {
                    console.log(error);
                    button.text('Error');
                },
            });
        });
    },
    addHubSpotFormLink() {
        var acf = window.acf;
        if (!acf) {
            return;
        }
        acf.add_action('ready append', function (element) {
            var form_div = $('.acf-field-form-layout-hubspot-form-id, .acf-field-hub-spot-form-form-id');

            form_div.find('.hubspot-form-link').remove();

            form_div.append('<div style="margin-top: 16px;" class="hubspot-form-link"></div>');

            var form_id = form_div.find('select').val();

            if (form_id) {
                var link = 'https://app.hubspot.com/forms/3988323/editor/' + form_id + '/edit/form';
                form_div.find('.hubspot-form-link').html('<a href="' + link + '" target="_blank">Edit HubSpot Form</a>');
            }

            form_div.find('select').on('change', function () {
                var hubspotFormId = $(this).val();
                if (hubspotFormId) {
                    var link = 'https://app.hubspot.com/forms/3988323/editor/' + hubspotFormId + '/edit/form';
                }
                form_div.find('.hubspot-form-link').html('<a href="' + link + '" target="_blank">Edit HubSpot Form</a>');
            });
        });
    },
};

$(document).ready(function () {
    Admin.init();
});
